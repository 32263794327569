<template>
  <v-row class="data-table-row">
    <v-col cols="12 cardBlock">
      <v-card class="employee-list mb-1">
        <div class="cardHeight">
          <v-card-title class="pa-6 pb-3">
            <p>{{ title }}</p>
            <v-chip
              v-for="filter in filters"
              :key="filter.on"
              class="ma-2"
              small
              close
              @click:close="$emit('click:removeChip', filter.on)"
            >
              {{ `${filter.chipTitle}` }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-autocomplete
              v-if="
                currentEntity &&
                currentEntity.entity &&
                currentEntity.entity.actions.tenant
              "
              v-model="selectedValues"
              :items="tenantList"
              :value="currentTenant"
              :search-input.sync="searchInputAcademy"
              @input="(val) => $emit('setCurrentTenant', val)"
              item-text="name"
              item-value="id"
              :label="currentEntity.entity.actions.tenant.name"
              multiple
              chips
            ></v-autocomplete>
            <v-btn
              v-if="
                currentEntity &&
                currentEntity.entity &&
                currentEntity.entity.actions.csvDownload &&
                showCSVBTN
              "
              @click="downloadCsv"
              icon
              :disabled="disableDownloadBtn"
            >
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="
                currentEntity &&
                currentEntity.entity &&
                currentEntity.entity.actions.csvEmail &&
                showCSVBTN
              "
              @click="emailCsv"
              icon
              :disabled="disableEmailBtn"
            >
              <v-icon>mdi-email-outline</v-icon>
            </v-btn>
            <v-btn
              @click="$emit('click:action', 'clear')"
              v-if="actions.includes('clear')"
              icon
            >
              <v-icon>mdi-notification-clear-all</v-icon>
            </v-btn>
            <v-btn
              @click="$emit('click:action', 'filter')"
              v-if="actions.includes('filter')"
              icon
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
            <v-btn @click="$emit('click:action', 'reload')" icon>
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
            <v-btn
              @click="$emit('click:action', 'create')"
              v-if="actions.includes('create')"
              icon
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :fixed-header="true"
            :items="items"
            :options.sync="options"
            :server-items-length="10"
            hide-default-footer
            item-key="name"
          >
            <template
              v-for="(h, idx) in headers"
              v-slot:[`header.${h.value}`]="{ headers }"
            >
              <v-tooltip top :key="idx">
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ h.text }}</span>
                </template>
                <span>{{ h.tooltip }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item`]="{ item, headers }">
              <tr>
                <td
                  v-for="(head, index) in headers"
                  :key="index + Math.random().toString(36).substr(2, 9)"
                >
                  <div
                    v-if="head.columnType === 'array[string]'"
                    class="d-flex flex-row flex-wrap"
                    style="min-width: 250px"
                  >
                    <v-chip
                      v-for="(val, idx) in item[head.value]"
                      :key="val + idx"
                      x-small
                      class="ma-1"
                    >
                      {{ val }}
                    </v-chip>
                  </div>
                  <div
                    v-else-if="head.columnType === 'array[map]'"
                    class="d-flex flex-row flex-wrap"
                    style="min-width: 250px"
                  >
                    <v-chip
                      v-for="(val, idx) in item[head.value]"
                      :key="val + idx"
                      x-small
                      class="ma-1 hover_bg"
                    >
                      <v-tooltip
                        top
                        :open-on-hover="false"
                        :open-on-click="true"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-if="head.display">
                            <span
                              v-on="on"
                              v-for="(field, index) in head.display"
                              :key="field + index"
                              >{{ val[field]
                              }}<span v-if="head.display.length - 1 > index"
                                >{{ head.displaySeprator }}
                              </span>
                            </span>
                          </span>
                          <span v-else v-on="on">{{ val[head.display] }} </span>
                        </template>
                        <span
                          v-if="
                            head.onHoverDisplay && head.onHoverDisplay === '*'
                          "
                        >
                          {{ val }}
                        </span>
                        <span
                          v-else
                          :key="v + i"
                          v-for="(v, i) in head.onHoverDisplay"
                          >{{ val[v] }} ,
                        </span>
                      </v-tooltip>
                    </v-chip>
                  </div>
                  <div
                    v-else-if="head.columnType === 'map'"
                    class="d-flex flex-row flex-wrap"
                    style="min-width: 250px"
                  >
                    <v-chip x-small class="ma-1 hover_bg" v-if="item[head.value]">
                      <v-tooltip
                        top
                        :open-on-hover="false"
                        :open-on-click="true"
                      >
                        <template v-slot:activator="{ on }">
                          <span v-if="head.display" v-on="on">
                            <span
                              v-for="(h, index) in head.display"
                              :key="index"
                            >
                              <span>
                                {{ item[head.value][h] }}
                              </span>
                              <span v-if="head.display.length - 1 > index"
                                >{{ head.displaySeprator }}
                              </span>
                            </span>
                          </span>
                        </template>
                        <span
                          v-if="
                            head.onHoverDisplay && head.onHoverDisplay === '*'
                          "
                        >
                          {{ item[head.value] }}
                        </span>
                      </v-tooltip>
                    </v-chip>
                  </div>
                  <div v-else-if="head.link">
                    <a
                      :href="item[head.value]"
                      :target="head.link.open_in_new_tab ? '_blank' : ''"
                    >
                      {{ item[head.value] }}
                    </a>
                  </div>
                  <div v-else>
                    {{ item[head.value] }}
                  </div>
                </td>
              </tr>
            </template>
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <v-btn @click="handleAction('view', item.id)" text small color="success">
                <v-icon left>
                  mdi-card-search-outline
                </v-icon>
                View
              </v-btn>
              <v-btn text small color="error">
                <v-icon left>
                  mdi-delete
                </v-icon>
                Delete
              </v-btn>
            </template> -->
          </v-data-table>
        </div>
        <v-pagination
          v-if="calculatePages"
          class="py-5 mt-5"
          v-model="page"
          :length="pagination.totalNoPages"
          :total-visible="7"
          @input="changePage"
        ></v-pagination>
        <div class="no-record-text pt-10 pb-8" v-if="!calculatePages">
          <h1>No Records Found</h1>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
import EntityService from "@/core/services/entity.service";
export default {
  name: "DataTable",
  props: {
    showCSVBTN: {
      type: Boolean,
      default: false,
    },
    currentEntity: {
      type: Object,
    },
    title: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    currentTenant: {
      type: Object,
    },
    tenantList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      page: 1,
      disableDownloadBtn: false,
      disableEmailBtn: false,
      searchInputAcademy: null,
      selectedValues: "",
      options: {},
    };
  },

  created() {
    this.searchAcademyDebounced = debounce(this.searchAcademy.bind(this), 1000);
  },

  methods: {
    searchAcademy(val) {
      EntityService.searchAcademyByName(val).then((tenantList) => {
        this.$emit("updateTenantList", tenantList);
      });
    },
    orderRecord(payload) {
      if (JSON.stringify(payload.sortBy) != []) {
        this.$emit("click:action", "order", payload);
      }
    },
    downloadCsv() {
      this.disableDownloadBtn = true;
      this.downloadEntityCsv().then(() => {
        this.disableDownloadBtn = false;
      });
    },
    emailCsv() {
      this.disableEmailBtn = true;
      this.emailEntityCsv().then(() => {
        this.disableEmailBtn = false;
      });
    },
    changePage(pageNo) {
      if (pageNo == 1) {
        this.$router.push({
          query: {},
        });
      } else {
        this.$router.push({
          query: {
            pageNo,
          },
        });
      }
    },

    handleAction(action, item = null) {
      switch (action) {
        case "view":
          this.$emit("click:viewAction", item);
      }
    },

    getFilterValue(filterValue) {
      if (typeof filterValue === "object") {
        let value = "";
        Object.values(filterValue).forEach((val, index) => {
          value = value + val;

          if (!(Object.values(filterValue).length == index + 1)) {
            value = value + ", ";
          }
        });
        return value;
      } else {
        return filterValue;
      }
    },

    filterOperation(filterOperation) {
      let fieldOperation = "";
      switch (filterOperation) {
        case "$lt":
          fieldOperation = " < ";
          break;
        case "$lte":
          fieldOperation = " <= ";
          break;
        case "$gt":
          fieldOperation = " > ";
          break;
        case "$gte":
          fieldOperation = " >= ";
          break;
        case "$not_equal":
          fieldOperation = " != ";
          break;
        default:
          fieldOperation = " = ";
      }
      return fieldOperation;
    },
    ...mapActions(["downloadEntityCsv", "emailEntityCsv"]),
  },
  watch: {
    pagination: {
      immediate: true,
      deep: true,
      handler({ pageNo }) {
        this.page = pageNo || 1;
      },
    },
    options: {
      deep: true,
      handler(val) {
        if (JSON.stringify(val.sortBy) != []) {
          this.orderRecord(val);
        }
      },
    },
    searchInputAcademy(val) {
      this.searchAcademyDebounced(val);
    },
  },
  computed: {
    entityActions() {
      const allowedActions = ["create", "filter"];
      let availActions = [];

      if (this.currentEntity?.entity?.actions) {
        availActions = Object.keys(this.currentEntity?.entity?.actions).filter(
          (action) => allowedActions.includes(action)
        );
      }

      if (availActions.length > 0 && availActions.includes("filter")) {
        availActions.push("clear");
      }

      return availActions;
    },

    calculatePages() {
      var numberOfPages = this.pagination.totalNoPages;
      console.log("Numb of pages", numberOfPages);
      if (numberOfPages > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
// .cardBlock {
//   position: relative;
//   .v-card__title{
//     position: sticky;
//     top: 0;
//     background-color: #FFFFFF;
//     z-index: 3;
//   }
//   ::-webkit-scrollbar {
//     width: 5px;
//   }
//   /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
//   }
//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 10px;
//   }
//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #888;
//   }
//   .cardHeight {
//     height: 75vh;
//     overflow: auto;
//   }
// }
.data-table-row {
  .v-data-table-header {
    th {
      white-space: nowrap;
      border-top: 1px solid #dddddd;
      color: rgba(0, 0, 0, 0.87) !important;
      min-width: 105px;
      &:last-child {
        display: none;
      }
    }
    th.sortable.active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  tbody {
    tr {
      td {
        min-width: 105px;
        &:last-child {
          display: none;
        }
        div {
          margin: 10px 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: auto;
          height: 100px;
        }
        ::-webkit-scrollbar {
          width: 5px;
        }
        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #888;
        }
      }
    }
  }
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
table th + th {
  border-left: 1px solid #dddddd;
}
table td + td {
  border-left: 1px solid #dddddd;
}
.activeRow {
  background-color: rgba(0, 0, 0, 0.05);
}
.hover_bg:hover {
  background-color: #7f8285 !important;
}
.no-record-text {
  color: #757575;
  text-align: center;
}
</style>
