var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"data-table-row"},[_c('v-col',{attrs:{"cols":"12 cardBlock"}},[_c('v-card',{staticClass:"employee-list mb-1"},[_c('div',{staticClass:"cardHeight"},[_c('v-card-title',{staticClass:"pa-6 pb-3"},[_c('p',[_vm._v(_vm._s(_vm.title))]),_vm._l((_vm.filters),function(filter){return _c('v-chip',{key:filter.on,staticClass:"ma-2",attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.$emit('click:removeChip', filter.on)}}},[_vm._v(" "+_vm._s(("" + (filter.chipTitle)))+" ")])}),_c('v-spacer'),(
              _vm.currentEntity &&
              _vm.currentEntity.entity &&
              _vm.currentEntity.entity.actions.tenant
            )?_c('v-autocomplete',{attrs:{"items":_vm.tenantList,"value":_vm.currentTenant,"search-input":_vm.searchInputAcademy,"item-text":"name","item-value":"id","label":_vm.currentEntity.entity.actions.tenant.name,"multiple":"","chips":""},on:{"update:searchInput":function($event){_vm.searchInputAcademy=$event},"update:search-input":function($event){_vm.searchInputAcademy=$event},"input":function (val) { return _vm.$emit('setCurrentTenant', val); }},model:{value:(_vm.selectedValues),callback:function ($$v) {_vm.selectedValues=$$v},expression:"selectedValues"}}):_vm._e(),(
              _vm.currentEntity &&
              _vm.currentEntity.entity &&
              _vm.currentEntity.entity.actions.csvDownload &&
              _vm.showCSVBTN
            )?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disableDownloadBtn},on:{"click":_vm.downloadCsv}},[_c('v-icon',[_vm._v("mdi-download-outline")])],1):_vm._e(),(
              _vm.currentEntity &&
              _vm.currentEntity.entity &&
              _vm.currentEntity.entity.actions.csvEmail &&
              _vm.showCSVBTN
            )?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disableEmailBtn},on:{"click":_vm.emailCsv}},[_c('v-icon',[_vm._v("mdi-email-outline")])],1):_vm._e(),(_vm.actions.includes('clear'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:action', 'clear')}}},[_c('v-icon',[_vm._v("mdi-notification-clear-all")])],1):_vm._e(),(_vm.actions.includes('filter'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:action', 'filter')}}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:action', 'reload')}}},[_c('v-icon',[_vm._v("mdi-autorenew")])],1),(_vm.actions.includes('create'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click:action', 'create')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"fixed-header":true,"items":_vm.items,"options":_vm.options,"server-items-length":10,"hide-default-footer":"","item-key":"name"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h,idx){return {key:("header." + (h.value)),fn:function(ref){
            var headers = ref.headers;
return [_c('v-tooltip',{key:idx,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tooltip))])])]}}}),{key:"item",fn:function(ref){
            var item = ref.item;
            var headers = ref.headers;
return [_c('tr',_vm._l((headers),function(head,index){return _c('td',{key:index + Math.random().toString(36).substr(2, 9)},[(head.columnType === 'array[string]')?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"min-width":"250px"}},_vm._l((item[head.value]),function(val,idx){return _c('v-chip',{key:val + idx,staticClass:"ma-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(val)+" ")])}),1):(head.columnType === 'array[map]')?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"min-width":"250px"}},_vm._l((item[head.value]),function(val,idx){return _c('v-chip',{key:val + idx,staticClass:"ma-1 hover_bg",attrs:{"x-small":""}},[_c('v-tooltip',{attrs:{"top":"","open-on-hover":false,"open-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(head.display)?_c('span',_vm._l((head.display),function(field,index){return _c('span',_vm._g({key:field + index},on),[_vm._v(_vm._s(val[field])),(head.display.length - 1 > index)?_c('span',[_vm._v(_vm._s(head.displaySeprator)+" ")]):_vm._e()])}),0):_c('span',_vm._g({},on),[_vm._v(_vm._s(val[head.display])+" ")])]}}],null,true)},[(
                          head.onHoverDisplay && head.onHoverDisplay === '*'
                        )?_c('span',[_vm._v(" "+_vm._s(val)+" ")]):_vm._l((head.onHoverDisplay),function(v,i){return _c('span',{key:v + i},[_vm._v(_vm._s(val[v])+" , ")])})],2)],1)}),1):(head.columnType === 'map')?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"min-width":"250px"}},[(item[head.value])?_c('v-chip',{staticClass:"ma-1 hover_bg",attrs:{"x-small":""}},[_c('v-tooltip',{attrs:{"top":"","open-on-hover":false,"open-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [(head.display)?_c('span',_vm._g({},on),_vm._l((head.display),function(h,index){return _c('span',{key:index},[_c('span',[_vm._v(" "+_vm._s(item[head.value][h])+" ")]),(head.display.length - 1 > index)?_c('span',[_vm._v(_vm._s(head.displaySeprator)+" ")]):_vm._e()])}),0):_vm._e()]}}],null,true)},[(
                          head.onHoverDisplay && head.onHoverDisplay === '*'
                        )?_c('span',[_vm._v(" "+_vm._s(item[head.value])+" ")]):_vm._e()])],1):_vm._e()],1):(head.link)?_c('div',[_c('a',{attrs:{"href":item[head.value],"target":head.link.open_in_new_tab ? '_blank' : ''}},[_vm._v(" "+_vm._s(item[head.value])+" ")])]):_c('div',[_vm._v(" "+_vm._s(item[head.value])+" ")])])}),0)]}}],null,true)})],1),(_vm.calculatePages)?_c('v-pagination',{staticClass:"py-5 mt-5",attrs:{"length":_vm.pagination.totalNoPages,"total-visible":7},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),(!_vm.calculatePages)?_c('div',{staticClass:"no-record-text pt-10 pb-8"},[_c('h1',[_vm._v("No Records Found")])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }